import { Image } from "./image";
import { Link } from "react-router-dom";

export const Calltoaction = (props) => {
    return (
        <div className='text-center cta'>
            <div className='container'>
                <div className='row mt-2'>
                    <div className='col-xs-10 col-xs-offset-1'>
                        <h2 className="cto1">"People work for money, but go the extra mile for recognition, praise, and rewards." <br /> - Dale Carnegie </h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='portfolio-items'>
                        <div className='col-xs-12 intro-text'>
                            <Link to='/signup'>
                                <div
                                    className='btn btn-custom2 page-scroll'
                                >

                                    Get Started with Crew Coin

                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}